<template>

	<!-- Authors Table Card -->
	<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
		<template #title>
			<a-row type="flex" align="middle">
				<a-col :span="24" :md="12">
					<h5 class="font-semibold m-0">Products</h5>
				</a-col>
				<a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
					<!-- <a-input-search v-model='q' @search="onSearch">
					</a-input-search>
					<a-radio-group size="small">
						<a-radio-button value="all">ALL</a-radio-button>
						<a-radio-button value="online">ACTIVE</a-radio-button>
					</a-radio-group> -->
				</a-col>
			</a-row>
		</template>
		<a-table :columns="columns" :data-source="data" :pagination="true">
			<template slot="productimg" slot-scope="avatar">
				<a-avatar shape="square" :src="avatar" />
			</template>			

			<template slot="editBtn" slot-scope="row">
				<a-button shape="circle" icon="edit" :data-id="row.key" class="btn-edit" @click="editproduct(row)">
				</a-button>
			</template>

			<template slot="delBtn" slot-scope="row">
				<a-button shape="circle" icon="delete" :data-id="row.key" class="btn-edit" @click="delproduct(row)">
				</a-button>
			</template>

			<template slot="moreBtn" slot-scope="row">
				<a-button shape="circle" icon="right" :data-id="row.key" class="btn-edit" @click="productdetail(row)">
				</a-button>
			</template>

			<template slot="categoryCol" slot-scope="categories">
				<template v-for="itm in categories">
				<a-tag :key="itm.categoryid"><router-link :to="{path:'categorydetail',query:{categoryid:itm.categoryid}}">{{ itm.name }}</router-link></a-tag>
				</template>
			</template>

			<template slot="competitorCol" slot-scope="row">
				{{(row?row.length:0)}}
			</template>
			
		</a-table>
	</a-card>
	<!-- / Authors Table Card -->

</template>

<script>
	import {search} from "@/api/product";
	import {deleteproduct} from '@/api/product';
	export default ({
		props: {
			data: {
				type: Array,
				default: () => [],
			},
			columns: {
				type: Array,
				default: () => [],
			},
		},
		data() {
			return {
				q:'',

			}
		},
		methods:{

			editproduct(key){
				this.$router.push({path:'/productedit',query:{productid:key}})
			},
			delproduct(key){
				deleteproduct(key).then(data=>{
					for (var i=0; i < this.data.length; i++)
						if (this.data[i].productKey==key)
							this.data.splice(i,1);
				});
			},
			productdetail(key){
				this.$router.push({path:'/productdetail', query:{productid:key}});
			},
			onSearch(v){
				search({q:v}).then(response=>{
					this.data=response;
				});
			}
		}
	})

</script>